/* eslint-disable no-useless-return */
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import VueCookies from 'vue-cookies'

Vue.use(VueRouter)

const AuthenticatedSignout = (to, from, next) => {
  if (store.getters.getter_dhl_authorize === true) {
    store.dispatch('auth_portal_expired')
      .then(
        VueCookies.keys().forEach(cookie => VueCookies.remove(cookie)),
        localStorage.clear(),
        next('/')
      )
      .catch((error) => {
        console.log(error.response)
      })
  } else {
    store.dispatch('auth_portal_expired')
    localStorage.clear()
    VueCookies.keys().forEach(cookie => VueCookies.remove(cookie))
  }
  next('/')
}

// eslint-disable-next-line no-unused-vars
const AuthenticatedCheckerOld = (to, from, next) => {
  if (store.getters.getter_dhl_authorize === true) {
    // ตัวแปร store.getters.ชื่อตัวเเปรgetters ของ authorize
    next()
    return
  } else {
    store.dispatch('auth_portal_check').then(response => {
      if (response.status === 'success') {
        console.log(response)
        next()
        return
      } else {
        next('/logout')
      }
    })
  }
}

const AuthenticatedChecker = (to, from, next) => {
  if (store.getters.getter_dhl_authorize === true) {
    // ตัวแปร store.getters.ชื่อตัวเเปรgetters ของ authorize
    next()
    return
  } else {
    store.dispatch('action_check_session').then(response => {
      if (response.status === 'OK') {
        console.log(response)
        next()
        return
      } else {
        next('/logout')
      }
    })
  }
}

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: { title: 'Login | DHL e-WitholdingTax' }
  },
  {
    path: '/report_sap',
    name: 'ReportSAP',
    component: () => import('../views/reportSAP.vue'),
    beforeEnter: AuthenticatedChecker,
    meta: { title: 'รายการรับชำระที่ไม่พบข้อมูล | DHL e-WitholdingTax' }
  },
  {
    path: '/report_ibs',
    name: 'ReportIBS',
    component: () => import('../views/reportIBS.vue'),
    beforeEnter: AuthenticatedChecker,
    meta: { title: 'รายการรอชำระ | DHL e-WitholdingTax' }
  },
  {
    path: '/total_report',
    name: 'TotalReport',
    component: () => import('../views/totalReport.vue'),
    beforeEnter: AuthenticatedChecker,
    meta: { title: 'รายงานการรับชำระภาษีหัก ณ ที่จ่าย | DHL e-WitholdingTax' }
  },
  {
    path: '/except_information',
    name: 'ExceptInformation',
    component: () => import('../views/exceptInformation.vue'),
    beforeEnter: AuthenticatedChecker,
    meta: { title: 'รายการข้อมูลยกเว้น | DHL e-WitholdingTax' }
  },
  {
    path: '/except_information_returned',
    name: 'ExceptInformationReturned',
    component: () => import('../views/exceptInformationReturned.vue'),
    beforeEnter: AuthenticatedChecker,
    meta: { title: 'รายการข้อมูลยกเว้นออกคืน | DHL e-WitholdingTax' }
  },
  {
    path: '/logout',
    name: 'Logout',
    beforeEnter: AuthenticatedSignout
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const DEFAULT_TITLE = 'DHL e-WitholdingTax'
router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE
  })
})
export default router

/* eslint-disable no-self-assign */
/* eslint-disable handle-callback-err */
/* eslint-disable quote-props */
/* eslint-disable no-undef */
import Vue from 'vue'
import VueAxios from 'vue-axios'
import axios from 'axios'
import Vuex from 'vuex'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies, { expires: '60MIN', path: '/', domain: '', secure: process.env.NODE_ENV === 'production' ? true : '', sameSite: 'Lax' })

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
Vue.use(VueAxios, axios)
Vue.use(Vuex)
const state = {
  refreshtoken: '',
  accesstoken: '',
  profile: {},
  authorize: false
}

const mutations = {
  auth_portal_check (state, data) {
    state.refreshtoken = data
    state.accesstoken = ''
    state.authorize = false
    state.profile = state.profile
  },
  auth_portal_success (state, data) {
    state.refreshtoken = state.refreshtoken
    state.accesstoken = data.accesstoken
    state.authorize = true
    state.profile = state.profile
  },
  auth_portal_expired (state) {
    state.refreshtoken = ''
    state.accesstoken = ''
    state.authorize = false
    state.profile = {}
  },
  account_portal_check (state, data) {
    state.refreshtoken = state.refreshtoken
    state.accesstoken = state.accesstoken
    state.authorize = state.authorize
    state.profile = state.profile
  },
  account_portal_success (state, data) {
    var currentuser = data.business_owner.find(d => (d.username).toLowerCase() === (localStorage.getItem('DHL_Username')).toLowerCase())
    data.currentuser = currentuser
    state.refreshtoken = state.refreshtoken
    state.accesstoken = state.accesstoken
    state.authorize = true
    state.profile = data
  },
  account_portal_notfound (state) {
    state.refreshtoken = ''
    state.accesstoken = ''
    state.authorize = false
    state.profile = {}
  },
  auth_signout (state) {
    state.refreshtoken = ''
    state.accesstoken = ''
    state.authorize = false
  },
  mut_set_account_portal_success (state, data) {
    VueCookies.set('dth_wht_accesstoken', data.access_token, '30MIN', '/', '', process.env.NODE_ENV === 'production' ? true : '', 'LAX')
    VueCookies.set('dth_wht_refreshtoken', data.refresh_token, '60MIN', '/', '', process.env.NODE_ENV === 'production' ? true : '', 'LAX')
    state.refreshtoken = data.refresh_token
    state.accesstoken = data.access_token
    state.authorize = true
  },
  mut_set_account_portal_fail (state) {
    state.refreshtoken = ''
    state.accesstoken = ''
    state.authorize = false
  },
  mut_set_profile_portal_success (state, data) {
    state.profile = data
    state.authorize = true
  },
  mut_set_profile_portal_fail (state) {
    state.profile = {}
    state.refreshtoken = ''
    state.accesstoken = ''
    state.authorize = false
  }
}

const getters = {
  getter_dhl_accesstoken: state => state.accesstoken,
  getter_dhl_refreshtoken: state => state.refreshtoken,
  getter_dhl_profile: state => state.profile,
  getter_dhl_authorize: state => state.authorize
}

const actions = {

  auth_portal_success ({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit('auth_portal_success', data)
    })
  },
  auth_portal_expired ({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit('auth_portal_expired', data)
    })
  },
  account_portal_success ({ commit }, data) {
    commit('account_portal_success', data)
  },
  account_portal_notfound ({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit('account_portal_notfound', data)
    })
  },

  // auth_portal_check ({ commit, dispatch }, data) {
  //   return new Promise((resolve, reject) => {
  //     if (localStorage.getItem('DHL_Access') != null) {
  //       commit('auth_portal_check', JSON.parse(localStorage.getItem('DHL_Access')).data.refreshtoken)
  //       axios.post(process.env.VUE_APP_REGETAXSP_API + '/getaccusr_getbusiness', { token: JSON.parse(localStorage.getItem('DHL_Access')).data.refreshtoken }, { withCredentials: false })
  //         .then((response) => {
  //           if (response.data.status === 'success') {
  //             resolve(response.data)
  //             commit('auth_portal_success', response.data)
  //             dispatch('account_portal_check', response.data)
  //           } else {
  //             commit('auth_portal_expired')
  //             resolve(response.data)
  //             localStorage.removeItem('DHL_Access')
  //             localStorage.removeItem('DHL_Username')
  //           }
  //         })
  //     } else {
  //       commit('auth_portal_check', '')
  //       commit('auth_portal_expired')
  //       resolve({ result: 'fail' })
  //       localStorage.removeItem('DHL_Access')
  //       localStorage.removeItem('DHL_Username')
  //     }
  //   })
  // },

  // account_portal_check ({ commit, dispatch }, data) {
  //   commit('account_portal_check')
  //   axios.post(process.env.VUE_APP_REGETAXSP_API + '/usr_getbusiness', { refreshToken: JSON.parse(localStorage.getItem('DHL_Access')).data.refreshtoken, accessToken: data.accesstoken }, { withCredentials: false })
  //     .then((response) => {
  //       if (response.data.result === 'Success') {
  //         commit('account_portal_success', response.data.data[0])
  //       } else {
  //         dispatch('account_portal_notfound', response.data)
  //         commit('auth_portal_expired')
  //       }
  //     }).catch((error) => {
  //       console.log(error)
  //       commit('auth_portal_expired')
  //     })
  // },

  async action_secure_auth_portal_login ({ commit, dispatch }, data) {
    let response = { status: 'OK', errorMessage: '' }
    try {
      const payload = data
      const callApi = await axios.post(process.env.VUE_APP_SERVICE_API + '/api/login/v2', payload)
      if (callApi.data.status === 'OK') {
        VueCookies.set('dth_wht_bizid', callApi.data.result.biz_id, '60MIN', '/', '', process.env.NODE_ENV === 'production' ? true : '', 'LAX')
        commit('mut_set_account_portal_success', callApi.data.result)
        const callActionGetProfileBiz = await dispatch('action_secure_get_profile_biz')
        if (callActionGetProfileBiz.status === 'OK') {
          response = { status: 'OK', errorMessage: '' }
        } else {
          response = callActionGetProfileBiz
        }
      } else {
        response = { status: 'ER', errorMessage: '' }
        commit('mut_set_account_portal_fail')
      }
    } catch (err) {
      console.log(err)
      commit('mut_set_account_portal_fail')
      if ('response' in err) {
        response = { status: 'ER', errorMessage: err.response.data.errorMessage }
      } else {
        response = { status: 'ER', errorMessage: String(err) }
      }
    }
    return new Promise((resolve) => {
      resolve(response)
    })
  },

  async action_secure_get_profile_biz ({ commit, dispatch }) {
    let response = { status: 'OK', errorMessage: '' }
    try {
      const payload = {
        accesstoken: VueCookies.get('dth_wht_accesstoken'),
        biz_id: VueCookies.get('dth_wht_bizid')
      }
      const callApi = await axios.post(process.env.VUE_APP_SERVICE_API + '/api/usr_getbusiness', payload)
      if (callApi.data.status === 'OK') {
        commit('mut_set_profile_portal_success', callApi.data.result)
      } else {
        commit('mut_set_profile_portal_fail')
        response = { status: 'ER', errorMessage: '' }
      }
    } catch (err) {
      console.log(err)
      if ('response' in err) {
        response = { status: 'ER', errorMessage: err.response.data.errorMessage }
      } else {
        response = { status: 'ER', errorMessage: String(err) }
      }
    }
    return new Promise((resolve) => {
      resolve(response)
    })
  },

  async action_request_accesstoken ({ commit }) {
    var response = { status: 'OK', errorMessage: '', token: '' }
    try {
      if (VueCookies.isKey('dth_wht_accesstoken') === true) {
        response.status = 'OK'
        response.errorMessage = ''
        response.token = VueCookies.get('dth_wht_accesstoken')
      } else if (VueCookies.isKey('dth_wht_refreshtoken') === true && VueCookies.isKey('dth_wht_bizid') === true) {
        const payload = {
          refreshtoken: VueCookies.get('dth_wht_refreshtoken')
        }
        const callApi = await axios.post(process.env.VUE_APP_SERVICE_API + '/api/usr_getaccesstoken', payload)
        if (callApi.data.status === 'OK') {
          // response.status = 'OK'
          // response.errorMessage = ''
          // response.token = callApi.data.result.access_token
          // commit('mut_set_account_portal_success', callApi.data.result)
          if (VueCookies.get('dth_wht_bizid') === callApi.data.result.biz_id) {
            response.status = 'OK'
            response.errorMessage = ''
            response.token = callApi.data.result.access_token
            commit('mut_set_account_portal_success', callApi.data.result)
          } else {
            response.status = 'ER'
            response.errorMessage = 'Session Timeout'
            response.token = ''
            commit('mut_set_account_portal_fail')
          }
        } else {
          response.status = 'ER'
          response.errorMessage = callApi.data.errorMessage
          response.token = ''
          commit('mut_set_account_portal_fail')
        }
      } else {
        response.status = 'ER'
        response.errorMessage = 'Session timeout !!'
        response.token = ''
        commit('mut_set_account_portal_fail')
      }
    } catch (err) {
      response.status = 'ER'
      response.errorMessage = String(err)
      response.token = ''
      console.log(err)
      commit('mut_set_account_portal_fail')
    }
    return new Promise((resolve) => {
      resolve(response)
    })
  },

  async action_check_session ({ commit, dispatch }) {
    // eslint-disable-next-line no-unused-vars
    let response = { status: 'OK', errorMessage: '' }
    try {
      if (VueCookies.isKey('dth_wht_accesstoken') === true) {
        if (VueCookies.isKey('dth_wht_bizid') && VueCookies.isKey('dth_wht_refreshtoken') === true) {
          const callActionGetProfileBiz = await dispatch('action_secure_get_profile_biz')
          if (callActionGetProfileBiz.status === 'OK') {
            response = { status: 'OK', errorMessage: '' }
          } else {
            VueCookies.keys().forEach(cookie => VueCookies.remove(cookie))
            response = callActionGetProfileBiz
          }
        } else {
          VueCookies.keys().forEach(cookie => VueCookies.remove(cookie))
          response = { status: 'ER', errorMessage: 'Session Timeout' }
        }
      } else {
        const callActionGetAccessToken = await dispatch('action_request_accesstoken')
        if (callActionGetAccessToken.status === 'OK') {
          if (VueCookies.isKey('dth_wht_bizid') && VueCookies.isKey('dth_wht_refreshtoken') === true) {
            const callActionGetProfileBiz = await dispatch('action_secure_get_profile_biz')
            if (callActionGetProfileBiz.status === 'OK') {
              response = { status: 'OK', errorMessage: '' }
            } else {
              VueCookies.keys().forEach(cookie => VueCookies.remove(cookie))
              response = callActionGetProfileBiz
            }
          } else {
            VueCookies.keys().forEach(cookie => VueCookies.remove(cookie))
            response = { status: 'ER', errorMessage: 'Session Timeout' }
          }
        } else {
          VueCookies.keys().forEach(cookie => VueCookies.remove(cookie))
          response = { status: 'ER', errorMessage: 'Session Timeout' }
        }
      }
    } catch (err) {
      console.log(err)
      VueCookies.keys().forEach(cookie => VueCookies.remove(cookie))
      response = { status: 'ER', errorMessage: 'Session Timeout' }
    }
    return new Promise((resolve) => {
      resolve(response)
    })
  }

}
const store = new Vuex.Store({
  state: state,
  mutations: mutations,
  getters: getters,
  actions: actions
})
global.store = store
export default store
